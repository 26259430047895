import axios from "axios";
const axiosMain = axios.create({
  baseURL: 'https://backend.hctesting.site/api/v1/',
  headers: { "Content-Type": "application/json", Accept: "application/json" }
});

axiosMain.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem("authToken")}`;
  return config;
});

export default axiosMain;