import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '../views/HomeView.vue';
// import ServicesView from '../views/ServicesView.vue';
// import ServiceDetailView from '../views/ServiceDetailView.vue';
// import ArticleView from '../views/ArticleView.vue';
// import ArticleListView from '../views/ArticleListView.vue';
// import ArticleDetailView from '../views/ArticleDetailView.vue';
// import RoomView from '../views/RoomView.vue';
// import RoomDetailView from '../views/RoomDetailView.vue';
import DoctorView from '../views/DoctorView.vue';
// import AboutView from '../views/AboutView.vue';
// import CareerView from '../views/CareerView.vue';
// import CareerDetailView from '../views/CareerDetailView.vue';
// import AuctionView from '../views/AuctionView.vue';
// import AuctionDetailView from '../views/AuctionDetailView.vue';
// import LinkView from '../views/LinkView.vue';
// import GalleryView from '../views/GalleryView.vue';
// import ContactView from '../views/ContactView.vue';
// import UnderConstructionView from '../views/UnderConstructionView.vue';

const underConstruction = true;

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/services',
  //   name: 'services',
  //   component: ServicesView
  // },
  // {
  //   path: '/services/detail/:slug',
  //   name: 'serviceDetail',
  //   component: ServiceDetailView
  // },
  // {
  //   path: '/articles',
  //   name: 'articles',
  //   component: ArticleView
  // },
  // {
  //   path: '/articles/category/:category',
  //   name: 'articleCategory',
  //   component: ArticleListView
  // },
  // {
  //   path: '/articles/detail/:slug',
  //   name: 'articleDetail',
  //   component: ArticleDetailView
  // },
  // {
  //   path: '/rooms',
  //   name: 'rooms',
  //   component: RoomView
  // },
  // {
  //   path: '/rooms/detail/:slug',
  //   name: 'roomDetail',
  //   component: RoomDetailView
  // },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: AboutView
  // },
  // {
  //   path: '/careers',
  //   name: 'career',
  //   component: CareerView
  // },
  // {
  //   path: '/careers/detail/:slug',
  //   name: 'careerDetail',
  //   component: CareerDetailView
  // },
  // {
  //   path: '/auction',
  //   name: 'auction',
  //   component: AuctionView
  // },
  // {
  //   path: '/auction/detail/:slug',
  //   name: 'auctionDetail',
  //   component: AuctionDetailView
  // },
  // {
  //   path: '/link',
  //   name: 'link',
  //   component: LinkView
  // },
  // {
  //   path: '/gallery',
  //   name: 'gallery',
  //   component: GalleryView
  // },
  // {
  //   path: '/contact-us',
  //   name: 'contact',
  //   component: ContactView
  // },
  // {
  //   path: '/under-construction',
  //   name: 'underConstruction',
  //   component: UnderConstructionView
  // },
  {
    path: '/:catchAll(.*)',
    redirect: '/doctors',
    beforeEnter(){
      location.href = process.env.BASE_URL + 'not-found'
    }
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(underConstruction){
    if(!to.matched.some(record => record.meta.requiresAuth)){
      // console.log(to);
      next();
    }
  }else{
    next();
  }
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //     if (!getCookie('session')) {
  //       if(to.name == 'login'){
  //         next();
  //       }else{
  //         showNotification('', 'Silakan login dahulu !', 2000, null);
  //         next({ name: 'login' });
  //       }
  //     } else {
  //       if(to.name == 'login'){
  //         next({name: 'profile'})
  //       }
  //       next()
  //     }
  // } else {
  //   // if (to.matched.some(record => record.meta.loginNotAllowed)) {
  //   //   next({ name: 'profile' });
  //   // }else{
  //   // }
  //   next()
  // }
})

export default router
